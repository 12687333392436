@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-style: bold;
}
body::-webkit-scrollbar {
  background-color: #ffffff00;
  width: 16px;
}
body::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

.gmail_quote {
  display: none;
}
